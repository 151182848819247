import React, { Component } from "react"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

const Demo = ({ props }) => {
  return (
    <div className="section-demo">
      <div className="section-demo__inner" />
      <div className="container container--pad has-text-centered has-text-left-desktop pad-t-6 pad-b-6 mar-t-3 mar-b-6">
        <div className="columns is-vcentered is-multiline">
          <div className="column is-full-tablet is-half-desktop">
            <h2 className="section-demo__header section-h__2 mar-b-3">
            "和超过全球1000个品牌一起通过我们的自动化营销工具来提升您的线上销售!
            </h2>
            <a href="/demo/" class="button is-cta is-pink">
              <FormattedMessage id="demo.b" />
            </a>
          </div>
          <div className="column is-full-tablet is-half-desktop">
            <div className="section-demo__prev sr-hidden sr-r">
              <Img fluid={props.esDashboard.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Demo
